.configurator {
    display: grid;
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    grid-template-rows: min-content 1fr min-content;

    .section-header {
        padding-top: 15px;
        .tabs {
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr 1fr;

            .tab {
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
                color: #759cff;
                margin: 0;
                padding: 0;
                padding-bottom: 15px;
                background: transparent;
                border: none;
                outline: none;
                box-sizing: border-box;
                border-bottom: 2px solid transparent;
                &.selected {
                    border-bottom: 2px solid #759cff;
                }
            }
        }
    }

    .tab-content-wrapper {
        transition: 0.5s ease-out;
        position: absolute;
        display: flex;
        flex-wrap: nowrap;
        height: 100%;

        .tab-content {
            width: 100vw;
            height: 100%;
            display: grid;
            overflow: hidden;
            overflow-y: scroll;
            grid-template-columns: 50% 50%;

            .left-section {
                padding: 30px;
                text-align: left;

                .align-left {
                    text-align: left;
                }

                .highlighted-title {
                    color: #759cff;
                }

                .greyed-text {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    color: #ffffff;
                    opacity: 0.42;
                }

                .choice-description {
                    color: #ffffff;
                    font-size: 18px;
                }

                .tab-content-grid {
                    display: grid;
                    padding-top: 30px;
                    grid-gap: 30px;
                    width: 100%;
                    grid-template-columns: 1fr 1fr;

                    &.sub-grid {
                        margin-top: 20px;
                        border-top: 2px solid #ffffff;
                        border-bottom: 2px solid #ffffff;
                        padding-bottom: 20px;

                        &:last-child {
                            border-bottom: none;
                        }

                        &.opened {
                            height: auto;
                            animation-duration: 300ms;
                            animation-name: slidedown;
                            overflow-x: visible;
                            overflow-y: visible;
                        }

                        &.closed {
                            height: 0;
                            padding: 0;
                            border-bottom: none;
                            border-top: none;
                            animation-duration: 200ms;
                            animation-name: slideup;
                            overflow-x: visible;
                            overflow-y: hidden;
                        }

                        @keyframes slideup {
                            from {
                                opacity: 100%;
                                height: auto;
                                overflow-x: visible;
                                overflow-y: hidden;
                            }

                            to {
                                height: 0;
                                opacity: 0;
                                padding: 0;
                                overflow-x: visible;
                                overflow-y: hidden;
                            }
                        }
                        @keyframes slidedown {
                            from {
                                opacity: 0;
                                height: 0;
                                overflow-x: visible;
                                overflow-y: hidden;
                                padding: 0;
                            }

                            to {
                                opacity: 100%;
                                overflow-x: visible;
                                overflow-y: hidden;
                                height: auto;
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                .left-section {
                    .title.large {
                        font-size: 32px;
                        line-height: 36px;
                        text-align: center;
                        br {
                            display: none;
                        }
                        .highlighted-title {
                            padding-left: 10px;
                        }
                    }
                    .tab-content-grid {
                        text-align: center;
                        & > div:not(.choice-description) {
                            display: flex;
                            justify-content: center;
                        }
                        &.grouped-buttons {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            div {
                                flex: 1 1 0;
                                padding: 10px;
                            }
                            .button {
                                min-width: 100%;
                                padding: 0 10px;
                                text-transform: unset;
                            }
                        }
                        grid-template-columns: 1fr;
                    }
                }
            }

            .platform-left-side,
            .type-left-side,
            .features-left-side {
                display: flex;
                align-items: center;
                position: relative;

                .mobile-picture {
                    position: absolute;
                    margin-top: 110px;

                    &.is-visible {
                        visibility: visible;
                        animation-duration: 500ms;
                        animation-name: slidein;
                    }

                    &.is-hidden {
                        visibility: hidden;
                        animation-duration: 300ms;
                        animation-name: slideout;
                    }

                    @keyframes slidein {
                        from {
                            visibility: visible;
                            margin-left: 100%;
                        }

                        to {
                            visibility: hidden;
                            margin-left: 0;
                        }
                    }

                    @keyframes slideout {
                        from {
                            visibility: hidden;
                            margin-left: 0;
                        }

                        to {
                            visibility: visible;
                            margin-left: 100%;
                        }
                    }
                }
            }

            .platform-left-side {
                background: url('../../assets/configuration-platform-back.svg') no-repeat left center;
            }

            .type-left-side {
                background: url('../../assets/configuration-type-back.svg') no-repeat left center;
            }

            .features-left-side {
                background: url('../../assets/configuration-features-back.svg') no-repeat left center;
            }

            @media only screen and (max-width: 768px) {
                grid-template-columns: 1fr;
                .platform-left-side,
                .type-left-side,
                .features-left-side {
                    display: none;
                }
            }
        }
    }

    .section-footer {
        display: grid;
        grid-template-areas: 'a b c d';
        box-sizing: border-box;
        min-height: 136px;
        padding: 30px;
        background: #38345a;
        justify-content: space-between;

        .prev-section-button {
            .button {
                min-width: auto;
            }
        }
        .estimate {
            display: flex;
            align-items: center;
            .estimate-label {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                padding: 0 25px;
                color: rgba(22, 222, 150, 0.8);
            }

            .estimate-value {
                font-style: normal;
                font-weight: bold;
                font-size: 48px;
                line-height: 57px;
                color: #ffffff;
                white-space: nowrap;
            }
        }

        .prev-mobile-button-wrapper {
            display: none;
        }

        @media only screen and (max-width: 768px) {
            width: 100vw;
            display: flex;
            flex-direction: column;
            padding: 15px 30px;
            align-items: unset;
            justify-content: space-between;

            .estimate {
                justify-content: space-between;
                .estimate-label {
                    padding-left: 0;
                }
                .estimate-value {
                    font-size: 32px;
                    line-height: 40px;
                }
            }
            .next-section-button {
                width: 100%;
                display: flex;
                justify-content: space-between;
                text-align: center;
                .prev-mobile-button-wrapper {
                    display: block;
                    .button {
                        min-width: auto;
                    }
                }
                .button {
                    min-width: 150px;
                }
            }
        }
    }
}
