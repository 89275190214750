@import '~normalize.css';

@font-face {
    font-family: 'SFPro-Display';
    src: url('./fonts/SFProDisplay-Regular.woff') format('woff');
}

body {
    font-family: SFPro-Display;
    background: #1a1736;
}

.main-wrapper {
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    overflow-y: scroll;
}

.content {
    position: relative;
    overflow: hidden;
    display: grid;
    width: 100vw;
    height: 100%;
    text-align: center;
}

a {
    text-decoration: none;
    color: inherit;
}
